import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, setTokenAutoRefreshEnabled } from "firebase/app-check";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import ReactObserver from 'react-event-observer';
import { getStorage, connectStorageEmulator } from "firebase/storage";


var firebaseConfig = {};

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

    firebaseConfig = {
        apiKey: "AIzaSyCqr8NT-Gxr_zZPSb_E44-hIjSbx4Z2svM",
        authDomain: "score-it-all.firebaseapp.com",
        databaseURL: "https://score-it-all-default-rtdb.firebaseio.com",
        projectId: "score-it-all",
        storageBucket: "score-it-all.appspot.com",
        messagingSenderId: "814872271082",
        appId: "1:814872271082:web:7611e34b9f4442dca29f93"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyB88hX_DWs8wNwbriybvQA6-V3i-cKeiSg",
        authDomain: "boardgametally.app",
        projectId: "boardgametally",
        storageBucket: "boardgametally.appspot.com",
        messagingSenderId: "752370803301",
        appId: "1:752370803301:web:3f4242125997ad11129c8c"
    };
}

const firebaseApp = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider("6LfFSYcnAAAAAP1JMooox2AO1HNbVxbHDVvZabXy"),
    isTokenAutoRefreshEnabled: false // Set to true to allow auto-refresh.
});

if (!window.navigator.userAgent.includes("Safari") && window.navigator.userAgent.includes("Chrome")) {
    setTokenAutoRefreshEnabled(appCheck, true);
}

export const config = firebaseConfig;
export const db = getFirestore(firebaseApp);
export const auth = getAuth();
const storage = getStorage();

if (window.location.hostname === "127.0.0.1") {
    console.log("127.0.0.1 detected!");
    connectFirestoreEmulator(db, "localhost", "8080");
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectStorageEmulator(storage, "127.0.0.1", "9199");
}

export const firebaseObserver = ReactObserver();
auth.onAuthStateChanged(function (user) {
    firebaseObserver.publish("authStateChanged", loggedIn())
});

export function loggedIn() {
    return !!auth.currentUser;
}


